html {
	scroll-behavior: smooth;
}
.raleway-normal{
	font-family: "Raleway", sans-serif;
  	font-optical-sizing: auto;
  	font-style: normal;
  	font-weight: 400;
}
.App {
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	min-height: 100vh;
	justify-content: center;
}
.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
  	max-width: 1200px;
	z-index: 1;
	margin-top: 15px;
}
.logo {
	width: 30%;
	max-width: 300px;
	min-width: 180px;
	margin-left: 40px;
	margin-top: 10px;
}
.title {
	text-align: center;
	margin: 20px;
	font-family: "Raleway", sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
	font-weight: bold;
}
.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

/* Selected Image */
.imageDisplayContainer {
	display: flex;
	position: absolute;
	z-index: 10;
	justify-content: center;
	align-items: center;
	background-color: #000c;
	width: 100vw;
	height: 100%;
	top: 0;
}
.imageDisplayBox {
	position: relative;
}
.imageDisplayBox > img {
	max-width: 90vw;
	border-radius: 20px;
	box-shadow: 0px 0px 10px #000;
	max-height: 90vh;
}
.closeImg {
	color: white;
	font-weight: bolder;
	transition: all .2s ease-in-out;
	cursor: pointer;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 111;
	top: 2%;
	right: 2%;
	font-size: 20px;
	position: absolute;
	background: #66C2C9;
}
.closeImg:hover{
	color: #66C2C9;
	background-color: unset;
	scale: 1.2;
}
/* Warning animation */
.stillSending {
	background-color: #a0ebf28a;
	display: flex;
	align-items: center;
	flex-direction: row;
	
	margin-top: 10px;
	padding: 0px 5px;
  	border-radius: 60px;
	box-shadow: 0px 0px 4px black;
}
.stillSending > p{
	margin: 0;
	padding: 15px 15px;
}

/* .f-modal-alert { */
	/* background-color: #fff;
	box-shadow: 0 0 10px 2px rgba(0,0,0,.2);
	border-radius: 4px;
	padding: 50px;
	margin: 20px; */

	/* margin-right: 10px; */
/* } */

/* .f-modal-alert .f-modal-icon {
	border-radius: 50%;
	border: 4px solid gray;
	box-sizing: content-box;
	height: 50px;
	margin: 5px auto;
	padding: 0;
	position: relative;
	width: 50px;
	
	&.f-modal-warning {
		border-color: #F8BB86;
		
		&:before {
			animation: pulseWarning 2s linear infinite;
			background-color: #fff;
			border-radius: 50%;
			content: "";
			display: inline-block;
			height: 100%;
			opacity: 0;
			position: absolute;
			width: 100%;
		}
		
		&:after {
			background-color: #fff;
			border-radius: 50%;
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 1;
		}
	}

	&.f-modal-warning .f-modal-body {
		background-color: #F8BB86;
		border-radius: 2px;
		height: 22px;
		left: 50%;
		margin-left: -2.5px;
		position: absolute;
		top: 10px;
		width: 3px;
		z-index: 2;
	}

	&.f-modal-warning .f-modal-dot {
		background-color: #F8BB86;
		border-radius: 50%;
		bottom: 10px;
		height: 3px;
		left: 50%;
		margin-left: -2.5px;
		position: absolute;
		width: 3px;
		z-index: 2;
	}
	
	+ .f-modal-icon {
		margin-top: 50px;
	}
}

.scaleWarning {
    animation: scaleWarning 0.75s infinite alternate;
}

.pulseWarningIns {
    animation: pulseWarningIns 0.75s infinite alternate;
}

@keyframes scaleWarning {
	0% {
		transform: scale(1);
	}
	
	30% {
		transform: scale(1.02);
	}
	
	100% {
		transform: scale(1);
	}
}

@keyframes pulseWarning {
	0% {
		background-color: #fff;
		transform: scale(1);
		opacity: 0.5;
	}

	30% {
		background-color: #fff;
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		background-color: #F8BB86;
		transform: scale(2);
		opacity: 0;
	}
}

@keyframes pulseWarningIns {
    0% {
        background-color: #F8D486;
    }

    100% {
        background-color: #F8BB86;
    }
} */

.navigateBtns {
	width: 95%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 1;
}
.btn{
  border: none;
	padding: 10px;
	margin-bottom: 20px;
	background-color: #66C2C9;
	color: white;
	font-weight: bolder;
	font-size: 15px;
	transition: all .2s ease-in-out;
	cursor: pointer;
}
.btn:hover{
  background-color: black;
}
/* .backBtn{

}
.nextBtn{

} */
.inputsBox {
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.input-wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	justify-content: space-between;
	min-width: 375px;
	width: 60%;
	gap: 5px;
	max-width: 500px;
	font-family: "Raleway", sans-serif;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
}
.input-wrapper label {
	width: 45%;
	padding: 15px;
	color: black;
	font-size: 17px;
	
}
.input-wrapper input, textarea {
	min-width: 240px;
	padding: 10px;
	font-family: "Raleway", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}
.input-wrapper textarea {
  resize: none;
  height: 70px;
}
.emailInputBox{
	display:flex; 
	flex-direction:row;
}
.notValidEmail{
	color: red;
	width: 45%;
	margin-top: -10px;
	font-size: 14px;
	text-align: right;
	min-width: 250px;
	max-width: 450px;
}
.gatheredInfoNotAccepted {
	color: red;
	width: 98%;
	padding-left: 30px;
	font-size: 14px;
	margin-top: -15px;
	text-align: left;
}
.emailEndPoint{
	border: none;
	background: unset;
	color: black;
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
}
.section .info{
  width: 90%;
  margin-bottom: 20px;
}
.section p{
  width: 60%;
}

/* Products */
.activeProductsBtns {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
}
.productSelectionDiv {
	width: 95%;
	padding-bottom: 5px;
}
.orderListReviewBox {
	gap: 15px;
	display: flex;
	flex-direction: column;
}
.orderListReview {
	display: flex;
	flex-direction: column;
}

.orderListReview p {
	margin: 0;
}
.orderListReviewDetails {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	padding: 10px;
	
	border-bottom: 1px solid black;
	background-color: #a0ebf28a;
  	color: black;
}
.costPart {
	gap: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.addOnsBox{
	padding: 10px;
	margin: auto;
	width: 92%;
}
.addOn{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px;	
}
.productSelectionDiv h3 {
	width: 100%;
	border-bottom: 1px solid black;
	padding-bottom: 10px;
	padding-left: 5px;
}
.activeProductsBtn {
	transition: all .2s ease-in-out;
	cursor: pointer;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 40px;
	height: 120px;
	width: 120px;
	box-shadow: 0px 0px 3px;
	background: white;
	min-width: 120px;
  	min-height: 120px;
}
.activeProductsBtn:hover{
  background-color: #555;
  color: white;
}
.ordermakingBox {
	position: relative;
	background-color: #f7f9f7;
	padding: 15px;
	box-shadow: 0px 0px 10px black;
	width: 100%;
	padding-bottom: 0;
	margin-bottom: 35px;
}
.productsBox {
	display: flex;
	gap: 20px;
	width: 90%;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	min-height: 40vh;
	margin-bottom: 20px;
}
.productBox {
	display: flex;
	align-items: center;
	flex-direction: row;
	min-height: 200px;
	width: 98%;
	position: relative;
	justify-content: flex-start;
	max-width: 800px;
	border-radius: 20px;
	box-shadow: 0px 2px 4px;
	gap: 30px;
	background-color: white;
	font-family: "Raleway", sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
	font-weight: 400;
}
.productInfoB{
	display: flex;
  	flex-direction: column;
}
.productImg {
	width: 30%;
	min-width: 200px;
	max-width: 200px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	max-height: 200px;
}

.productText{
	color: black;
}
.shortenQty{
	display: none;
}
.productPriceP{
	margin-top: 25px;
}
.orderlistBox {
	display: flex;
	gap: 10px;
	padding: 3px;
	/* width: fit-content; */
	overflow-x: auto;
	padding-bottom: 15px;
	width: 100%;
}
.orderlistItem {
	border-radius: 15px;
	height: 120px;
	width: auto;
	box-shadow: 0px 0px 3px;
	min-height: 120px;
	position: relative;
	background-color: #a0ebf28a;
	display: flex;
	align-items: center;
	/* min-width: 120px; */
}
.orderImg {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	height: 100%;
}
.bundleImg{
	width: unset;
}
.orderlistItemTextBox {
	height: 100%;
	padding: 0px 10px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.orderlistItemTextBox > h4{
	margin-top: 0;
	margin-bottom: 10px;
	min-width: 120px;
}
.orderlistItemText {
	margin: 0;
}

.removeOrder, .removeOrderOnSingleItem {
	position: absolute;
	right: 8px;
	top: 8px;
	color: white;
	background-color: red;
	font-weight: bolder;
	font-size: 13px;
	transition: all .2s ease-in-out;
	cursor: pointer;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 111;
}
.removeOrder:hover, .removeOrderOnSingleItem:hover{
	color: red;
	background-color: unset;
	scale: 1.2;
}
.removeOrderOnSingleItem {
	right: 0;
	top: 0;
	padding: 13px;
	font-size: 15px;
	font-weight: bolder;
	border-top-right-radius: 20px;
}
.selectProductBtn {
	border: none;
	padding: 13px;
	background-color: #66C2C9;
	color: white;
	font-weight: bolder;
	transition: all .2s ease-in-out;
	cursor: pointer;
	position: absolute;
	bottom: 0px;
  	right: 0px;
	border-bottom-right-radius: 20px;
	z-index: 1;
}
.orderlistTitle{
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid black;
	width: 100%;
}
.orderlistTitle > h3{
	width: unset;
	padding-bottom: 0px;
	border-bottom: unset;
	margin-bottom: 10px;
}
.submitBtn {
  height: 40px;
  width: 105px;
}
.submitBtn:hover, .newOrderBtn:hover, .selectProductBtn:hover{
  background-color: black;
}
.selectInListProductBtn:hover{
	scale: 1.2;
	font-size: 18px;
  	color: black;
  	background-color: unset;
}
.backToProductTypeSelection {
	position: absolute;
	top: 60px;
	right: 30px;
	z-index: 1;
}
.antyhingElseBox{
	align-items: start;
}
.notice {
	margin-top: 0;
	width: 56%;
	text-align: left;
	max-width: 500px;
	font-weight: 600;
	font-size: 12px;
}
.deliveryInfoP{
	padding: 0px 15px;
	margin-top: 0px;
}
.responseBox {
	position: absolute;
	width: 100%;
	height: 30px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00000085;
	bottom: 0;
	font-weight: bolder;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	left: 0;
}

/* Order Summary */
.summaryBoxes{
	display: flex;
	flex-direction: column;

	font-family: "Raleway", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}
.subTitle {
	/* border-bottom: 1px solid black;
	padding-bottom: 10px; */
	width: 100%;
}
.subTitle span {
	cursor: pointer;
}
.subTitle:not(:first-child){
	margin-top: 30px;
}
.summaryBox {
	padding: 15px;
	background-color: #a0ebf28a;
	display: flex;
	flex-direction: column;
	gap: 20px;
	box-shadow: 0px 0px 4px;
}
.summary-wrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}
.summary-wrapper p{
	margin: 0;
	width: fit-content;
}
.summary-wrapper p:last-child{
	font-weight: bold;
}
.order-wrapper {
	flex-direction: column;
	gap: 15px;
}
.orderContainer {
	display: flex;
	justify-content: space-between;
}
.imageContainerOfItem{
	width: 20%;
  	max-width: 90px;
}
.summaryOrderImg{
	width: 100%;
}
.orderItemContent {
	width: 85%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
}
.orderTitleAndCost{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.order-wrapper h4{
	margin: 0;
}
.order-wrapper h5{
	margin: 0;
	/* margin-right: 15px; */
}
.orderSummaryAddOnsPart {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
/* Ordermade part */
.backgroundCircle{
  position: absolute;
  height: 25vh;
  width: 100vw;
  background-color: #66C2C9;
}
.blueBackgroundTop {
	top: 0;
	border-bottom-right-radius: 140%;
}
.blueBackgroundBottom {
	bottom: 0;
	border-top-left-radius: 140%;
}
.ordermade {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	position: relative;
	width: 100%;
	align-items: center;
}
.portraitBox {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}
.portrait{
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px gray;
}
.ordermadeInformation {
	position: relative;
	background-color: white;
	padding: 15px;
	box-shadow: 0px 0px 10px black;
	max-height: 90vh;
	overflow: scroll;
	width: 90%;
	margin-top: -20px;
	padding-top: 0;
	padding-bottom: 0;
}
.orderMadeInformationContent {
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	max-width: 500px;
}
.orderMadeInformationContent > h3{
	margin-bottom: 0;
	margin-top: 5px;
	font-weight: normal;
}
.name-span{
	display: none;
  }
.orderMadeInformationContent > h2{
	margin-top: 5px;
}
.orderMadeInformationContent > p{
	margin-top: 0px;
	margin-bottom: 20px;
	line-height: 22.5px;
	text-align: center;
	font-size: 14px;
}
.btn.newOrderBtn{
	margin-bottom: 0;
}
.link{
  color: black;
  transition: all .2s ease;
}
.link:hover{
  color: skyblue;
}
.thanksBox {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}
.thanksText {
	line-height: 5.5px;
}
.carouselBox {
	height: unset;
	align-items: unset;
	padding-bottom: 32px;
	border-top: 1px solid #FDCA40;
  	border-bottom: 1px solid #FDCA40;
	padding-top: 15px;
}
.slick-slider .slick-list{
	padding: 3px 0px 0px 5px;
}
.slick-slide img {
	width: 90%;
	border-radius: 14px;
	box-shadow: 0px 0px 3px;
}
.carouselBox .slick-prev::before, .carouselBox .slick-next::before {
	/* color: black; */
	color: #66c2c9;
}
.attention{
	margin-top: 15px;
	font-size: 15px;
}
.copyRight {
	position: absolute;
	bottom: 5px;
	font-size: 14px;
	margin: 0;
	z-index: 11;
	width: 90%;
	text-align: center;
}
.developer{
	text-decoration: underline;
	color: black;
  	transition: all .2s ease;
}
.developer:hover{
	color: white ;
}

/* Loading Animation */
.lds-ellipsis {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.lds-ellipsis div {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffffff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 15px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 15px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 40px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 60px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media only screen and (max-width: 768px) {
	.container{
		justify-content: flex-start;
		height: 83%;
	}
	.content{
		margin-top: unset;
	}
	.ordermade{
		justify-content: flex-start;
		margin-top: 5px;
	}
	.ordermadeInformation {
		height: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: -35px;
		width: 95%;
	}
	.orderMadeInformationContent {
		height: 85%;
		justify-content: flex-start;
		gap: 20px;
		margin-top: 10px;
	}
	.orderMadeInformationContent > h3 {
		font-size: 30px;
		margin: 0;
	}
	.orderMadeInformationContent > h2 {
		margin: 0;
		display: none;
	}
	.nameSpan{
		display: inline;
	}
	.ordermakingBox {
		height: 90%;
		max-height: 95%;
		box-shadow: none;
		margin-bottom: 45px;
	}
	.logo {
		width: 50%;

		margin: 0px;
	}
	
	.orderlistSingleItem {
		height: 170px;
		min-width: 170px;
		flex-direction: column;
	}
	.activeProductsBtn{
		height: 170px;
		min-width: 170px;
	}
	.removeOnMobileV{
		display: none;
	}
	.orderImg{
		width: 50%;
		border-radius: 15px;
	}
	.bundleImg{
		width: 120px;
		border-top-right-radius: unset;
    	border-bottom-right-radius: unset;
	}
	.productSelectionDiv {
		height: unset;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 0px;
	}
	.productBox {
		flex-direction: column;
		gap: 15px;
		margin: auto;
		width: 280px;
		height: 280px;
	}
	.productBox h3,h5{
		margin: 0;
	}
	.productImg {
		max-height: 100%;
		position: absolute;
		width: 100%;
		border-radius: 20px;
		max-width: unset;
	}
	.productInfoB{
		z-index: 1;
		width: 100%;
		height: 100%;
	}
	.productInfoB h3 {
		background: #00000078;
		padding: 7px 0px 7px 15px;
		position: absolute;
		bottom: 36px;
		width: 265px;
		font-size: 16px;
	}
	.productInfoB p {
		display: none;
	}
	.productInfoB h5 {
		padding: 7px 15px 7px 0px;
		position: absolute;
		bottom: 0px;
		width: 265px;
		font-weight: bold;
		color: #FDCA40;
		color: black;
		font-size: 20px;
		text-align: right;
	}
	.quantitySelection {
		position: absolute;
		bottom: 42px;
		right: 0px;
		color: white;
		font-size: 16px;
	}
	.quantitySelection > .longerQty{
		display: none;
	}
	.quantitySelection > .shortenQty{
		display: unset;
	}
	p.productPriceP{
		display: unset;
		position: absolute;
		bottom: 9px;
		right: 10px;
		margin-bottom: 0;
		margin-top: 0;
		font-size: 16px;
	}
	.quantitySelection > .emailEndPoint {
		color: white;
	}
	.selectProductBtn, .removeOrderOnSingleItem{
		top: 12px;
		right: 12px;
		bottom: unset;
		border-radius: 8px;
		padding: 5px 10px;
		font-size: 20px;
	}
	.removeOrderOnSingleItem{
		left: 12px;
		right: 0;
		max-width: 40px;
	}
	.backToProductTypeSelection {
		top: 73px;
		right: 15px;
	}
	.productText{
		color: white;
	}
	.antyhingElseBox{
		align-items: center;
	}
	.inputsBox{
		min-height: 400px;
		height: 90%;
		overflow-x: hidden;
		justify-content: flex-start;
		width: 100%;
		/* margin-bottom: 20px;
		padding-bottom: 5px; */
	}
	.carouselBox {
		align-items: unset;
    	padding-bottom: 30px;
		padding-top: 12px;
	}
	.slick-prev::before, .slick-next::before {
		display: none;
	}
	.input-wrapper{
		flex-direction: column;
		align-items: center;
	}
	.input-wrapper label{
		width: 70%;
	}
	.input-wrapper input, textarea {
		width: 65%;
	}
	.emailInputBox{
		width: 70%;
		text-align: center;
	}
	.notValidEmail {
		width: 40%;
		text-align: center;
	}
	.gatheredInfoNotAccepted{
		padding-left: 15px;
	}
	.rentalSelect{
		position: relative;
		left: 83px;
	}
	.dateAndTimePick{
		position: relative;
		left: 58px;
	}
	.deliveryHourPick{
		position: relative;
		left: 65px;
	}
	.notice{
		width: 80%;
		margin-top: 20px;
	}
	.attention{
		margin-top: 0;
	}
	.input-wrapper .userContentLabel{
		padding: 0;
		max-width: 250px;
		text-align: center;
	}
	.orderListReview {
		width: 100%;
	}
	.imageContainerOfItem {
		width: 12%;
		max-width: 50px;
	}
	.addOn{
		padding-left: 0px;
		padding-right: 0px;
	}
	.costPartMobile {
		width: 70%;
		justify-content: left;
	}
	.imageDisplayBox {
		position: absolute;
		top: 22%;
	}
	.closeImg{
		top: 3%;
		right: 3%;
		font-size: 16px;
	}
	.stillSending {
		margin-bottom: 15px;
	}
	.thanksBox {
		margin-bottom: 0px;
	}
	.copyRight {
		font-size: 12px;
	}

}

/* For WebKit browsers like Chrome and Safari */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
	height: 7px;
	scrollbar-width: thin;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    /* background-color: #a0ebf287; */
    /* background-color: #FDCA40; */
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* For Internet Explorer and Edge */
/* For Firefox */
html {
    -ms-overflow-style: scrollbar;
    scrollbar-width: thin;
}
